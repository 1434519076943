@import "../../../data/config-scss/variables.scss";
$rowMargin: 4px 8px 4px 8px;

[id="login-content"] {
  background-color: $pageBackgroundColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  .login-main {
    width: 100%;
    flex-shrink: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .login-content {
    display: flex;
    flex-direction: column;
  }
  .link {
    text-decoration: underline !important;
  }
  .title-font {
    font-weight: bold;
  }

  & > div {
    min-width: 300px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin: 1em 0 1.2em;
    @media screen and (min-width: 401px) {
      align-items: center;
    }
  }

  .error-message {
    color: red;
    text-align: center;
  }

  .links {
    display: flex;
    flex-direction: column;
    min-height: 4em;
    font-size: 0.8rem;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 400px) {
      align-items: flex-end;
      margin-right: 2em;
    }
  }
  .cta-modal-btn {
    flex: 1;
    margin: 0 1em;
    font-size: 1.3em;
    max-width:150px;
  }
  .cta-modal-btn:active {
    background-color: $color1lighter;
  }
  .generic-input{
    font-size: 0.9em;
  }
}

.login-intro-text {
  padding: 0 1em;
  margin: auto;
  font-size: 0.9em;
}

.login-form-row {
  display: flex;
  flex: 1;
  border-bottom: 1px solid #b5b5b5;
  align-items: center;
  padding-left: 2em;
  width: 100%;
  box-sizing: border-box;
  max-width: 480px;


  .login-input-group {
    display: flex;
    flex-direction: column;
    flex:1;
    label {
      text-align: left;
      padding: 1em 0em 0 0.9em;
      margin: $rowMargin;
    }
    input {
      $this: &;
      display: inline-block;
      border: none;
      background-color: transparent;
      margin: $rowMargin;
      flex: 1;
      &:focus {
        border: transparent;
        box-shadow: none;
      }
      color: black;
      padding-left: 1px;
      margin-left: calc(0.9rem + 7px); 
      &:-webkit-autofill {
        -webkit-text-fill-color: $color1;
        -webkit-box-shadow: 0 0 0px 1000px rgba($color1lighter, 0.3) inset;
        transition: background-color 5000s ease-in-out 0s;
        padding-left: 1em;
      }
    }
  }
}



.login-text {
  margin-top: 0.8em;
  font-size: 0.9em;
}

i.icon-2x {
  font-size: 1.6em !important;
}

.login-tou-message {
  display: flex;
  justify-content: center;
}

.login-create-account {
  display: block;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;
}