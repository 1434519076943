

@import "../../../../data/config-scss/variables.scss";

$buttonSvgIconSize: 1.2em;


.klipso-leads-footer {
    display: flex;
    flex: 0 0 $klFooterHeight;
    justify-content: space-around;
    align-items: center;
    color: $klButtonsColor;
    background-color: $klButtonsBgColor;
    .klf-btn:not(.klf-text-btn){
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 .4em 0 .4em;
    }
}
.is-iphonex .klipso-leads-footer {
    padding: 0 0 12px 0;
}
.klf-btn {
    padding: .4em; // makes them easily tappable
    font-size: 2em;
    transition: opacity .5s;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(255,255,255,0.05);

    img {
        width: $buttonSvgIconSize;
        height: $buttonSvgIconSize;
    }
}
.klf-btn:active {
    background-color: lighten($klButtonsBgColor, 10%);
}
.klf-cta-btn:active {
    background-color: lighten($klButtonsBgColor, 10%);
    color: $klButtonsColor;
}
.klf-cta-btn {
    color: $klButtonsBgColor;
    background-color: $klButtonsColor;
}
.klf-scan-btn {
    width: $buttonSvgIconSize;
    height: $buttonSvgIconSize;
}

.klf-btn[disabled]{
    opacity: .4;
    cursor: default;
    background-color: $klButtonsBgColor
}

.klf-text-btn {
    margin: .5em;
    padding: .7em 1em .5em;
    font-size: 1.1em;
    font-weight: bold;
    border: 1px solid $klButtonsColor;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}


.klf-back-btn {
    display: flex;
    align-items: center;
}
.klf-back-btn-icon {
    margin: 0 .3em 0 .3em;
    font-size: .9em;
    background-color: $klButtonsColor;
}
.klf-back-btn-label {
    font-size: .45em;
    font-weight: bold;
}

.klf-sync-btn {
    text-align: left;

    &[disabled] {
        opacity: 1;
    }
}
.klf-sync-btn-checkicon {
    position: absolute;
    top: 10px;
    left: -8px;
    font-size: .7em;
    padding: 3px;
    border-radius: 15px;
    background-color: $klCheckColor;
    mask-size: 100%;
    //background-color: $klButtonsBgColor;
}

.klf-sync-btn-icon {
    position:relative;
    background-color: $klButtonsColor;
    mask-size: 100%;
}

[disabled] .klf-sync-btn-icon {
    opacity: .4;
    cursor: default;
    background-color: $klButtonsColor;
    mask-size: 100%;
}
